var overallWidth = $(window).outerWidth(),
		overallHeight = $(window).outerHeight(),
		md = 576,
		lg = 768,
		xl = 992,
		xxl = 1200;

$(window).resize(function() {
	overallWidth = $(window).outerWidth(),
	overallHeight = $(window).outerHeight();
});

// Check for children
$("ul.mainNav li").each(function() {
	if ($("> ul", this).length) {
		$(this).addClass("hasChildren");
	}
});

// ASSIGN ID'S TO ALL MENU ITEMS
var menuItemCount = 1;
$(".mainNav > li > a, .mainNav > li > button").each(function() {
	$(this).attr("id", "menuItem-" + menuItemCount);
	menuItemCount++;
});

$(window).resize(function() {
	if (overallWidth >= lg) {
		closeNavMobile();
	}
	
	if (overallWidth >= xl) {
		$(".mainNavRow").show();
		$(".mainNavRow-inner").css({
			height: "auto"
		});
	} else {
	}
});

// MOBILE NAV FUNCTIONS
var toggleText;
var currentNav = 0;

$(".menuToggle").click(function(e) {
	if (overallWidth < xl) {
		e.preventDefault();
		$(".mainNav").css({
			marginLeft: 0
		});
		if ($(this).hasClass("on")) {
			closeNavMobile();
		} else {
			openNavMobile();
		}
	}
});

function openNavMobile() {
	toggleText = $(".menuToggle").data("closedtext");
	$(".menuToggle").addClass("on");
	$(".mobileMenu-drawer").stop().slideDown(300, "easeOutQuad");
	$("body").addClass("noScroll");
	$(".menuToggle span").text(toggleText);
	navRowHeight();
}

function closeNavMobile() {
	toggleText = $(".menuToggle").data("opentext");
	$(".menuToggle").removeClass("on");
	$(".mobileMenu-drawer").stop().slideUp(300, "easeOutQuad", function() {
		$("body").removeClass("noScroll");
		$(".mainNav").nextAll("ul").remove();
		$(".mainNav").css({
			marginLeft: 0
		});
		$(".mobileNavTop > div").first().css({
			marginLeft: 0
		});
		$(".navBack").remove();
		currentNav = 0;
	});
	$(".menuToggle span").text(toggleText);
	$(".mainNav").css({
		"visibility": "visible"
	});
}

function navRowHeight() {
	var headerHeight = $("header").outerHeight();
	var searchHeight = $(".mainSearch-drawer").outerHeight();
	var mobileNavTopHeight = $(".mobileNavTop").outerHeight();
	$(".mainNavRow-inner").height(overallHeight - (headerHeight + searchHeight + mobileNavTopHeight));
}

$("body").on("click", ".navBack button.back", function(e) {

	e.preventDefault();

	currentNav = currentNav - 1;
	
	$(".mainNavRow-inner ul").first().animate({
		marginLeft: "-" + currentNav + "00%"
	}, 250, "easeOutQuad", function() {
		if (currentNav === 1) {
			$(".mainNav").css({
				"visibility": "hidden"
			});
			$(".mainNav").next("ul").css({
				"visibility": "visible"
			});
		} else if (currentNav === 2) {
			$(".mainNav").next("ul").css({
				"visibility": "hidden"
			});
		} else {
			$(".mainNav").css({
				"visibility": "visible"
			});
			$(".mainNav li").first().find("button").focus();
		}
	});

	$(".mobileNavTop > div").first().animate({
		marginLeft: "-" + currentNav + "00%"
	}, 250, "easeOutQuad", function() {
		$(".mobileNavTop div:last-child").remove();

		if (currentNav === 2) {
			$(".navBack").eq(0).css({
				"visibility": "hidden"
			});
		} else {
			$(".navBack").eq(0).css({
				"visibility": "visible"
			}).find("button").focus();
		}
	});

	$(".mainNavRow-inner ul").eq(currentNav).find("li").first().find("> a, button").focus();
});

$("body").on("keydown", ".navBack button.back", function(e) {
	if (overallWidth < xl) {
		if (e.key === "Tab" && e.shiftKey) {
			e.preventDefault();
			$(".mainSearch-right input").focus();
		} else if (e.key === "Tab") {
			//e.preventDefault();
			//$(".mainNavRow-inner ul:last-of-type").find("li:first > a").focus();
		} else if (e.key === "Enter") {
			if (currentNav <= 1) {
				$(".mainNav li").first().find("button").focus();
			}
		}
	}
});

$("body").on("keydown", ".mainNavRow-inner a, .mainNavRow-inner button", function(e) {
	if (overallWidth < xl) {
		if (e.key === "Tab" && e.shiftKey) {
			//e.preventDefault();
		} else if (e.key === "Tab") {
			if ($(this).closest("ul").hasClass("mainNav")) {
				if ($(this).parent().is(":last-child")) {
					e.preventDefault();
					$(".menuToggle").focus();
				}
			}
			if ($(this).closest("li").is(":last-child")) {
				e.preventDefault();
				$(".menuToggle").focus();
			}
		}
	}
});

$("body").on("keydown", ".mobileMenu-drawer", function(e) {
	if (e.key === "Escape") {
		$(".menuToggle").click();
	}
});

// click top level
$(".mainNavRow-inner").on("click", "a, button", function(e) {
	if (overallWidth < xl) {
		if ($(this).parent().hasClass("hasChildren")) {
			e.preventDefault();
	
			$(this).parent().find("> ul").clone(true, true).addClass("on").insertAfter($(this).closest("ul"));
	
			currentNav++;
	
			$(".mainNavRow-inner ul").first().animate({
				marginLeft: "-" + currentNav + "00%"
			}, 250, "easeOutQuad", function() {
				if (currentNav === 1) {
					$(".mainNav").css({
						"visibility": "hidden"
					});
					$(".mainNav").next("ul").css({
						"visibility": "visible"
					});
				} else if (currentNav === 2) {
					$(".mainNav").next("ul").css({
						"visibility": "hidden"
					});
				} else {
					$(".mainNav").css({
						"visibility": "visible"
					});
				}
			});
	
			$(".mobileNavTop > div").first().animate({
				marginLeft: "-" + currentNav + "00%"
			}, 250, "easeOutQuad", function() {
				if (currentNav === 2) {
					$(".navBack").eq(0).css({
						"visibility": "hidden"
					});
				} else {
					$(".navBack").eq(0).css({
						"visibility": "visible"
					});
				}
			});
			
			$("<div class='navBack'><button class='back'>Back</button></div>").appendTo(".mobileNavTop");

			$(".navBack button").focus();
		}
	}
});



// DESKTOP NAV FUNCTIONS
var topIndex;

// top level clicks
$("header .mainNav > li > a, header .mainNav > li > button").click(function(e) {

	if ($(this).parent().hasClass("hasChildren")) {
		e.preventDefault();
		topIndex = $(this).closest("li").index();

		if (overallWidth >= xl) {
			if ($(this).parent().hasClass("on")) {
				closeNav();
				$(this).parent().removeClass("on");
				$(this).attr("aria-expanded", "false");
			} else {
				$(".mainNav li a, .mainNav li button").parent().removeClass("on");
				$(".mainNav li button").attr("aria-expanded", "false");
				$(this).parent().addClass("on");
				$(this).attr("aria-expanded", "true");
				openNav();
			}

			$(".mainSearch-drawer").slideUp("fast");
			$(".mainSearch-toggle").removeClass("on");
		}
	}
});

// second level clicks
$("body").on("click", "a.navLink-left", function(e) {
	if ($(this).parent().hasClass("hasChildren")) {
		e.preventDefault();

		if (!$(this).parent().hasClass("on")) {
			var theId = $(this).attr("id");
			$(".menuDrawer-tertiary").attr("aria-labelledby", theId);

			$(".menuDrawer-mid").empty();
			$(".menuDrawer-left li").removeClass("on");
			$(this).parent().addClass("on");
			$(this).parent().find("> ul").clone(true, true).appendTo($(".menuDrawer-mid"));

			var fullLink = $(this).attr("href");
			var fullLinkText = $(this).text();
			//$(".menuDrawer-mid > ul").prepend("<li><a class='button accent1' href='" + fullLink + "'>See All " + fullLinkText + "</a></li>");

			// populate featured box
			var featureTitle = $(this).data("title");
			var featureCaption = $(this).data("caption");
			var featureUrl = $(this).data("url");
			var featureThumb = $(this).data("thumb");
			var featureAlt = $(this).data("alt");

			$(".menuDrawer-right").html("<div class='navFeatureBox'><div class='navFeatureBox-thumb'><img src='" + featureThumb + "' alt='" + featureAlt + "'></div><div class='navFeatureBox-content'><h2>" + featureTitle + "</h2><p>" + featureCaption + "</p><p><a class='arrowLink' href='" + featureUrl + "'>Read More</a></p></div></div>");
		} else {
			//$(".menuDrawer-mid").empty();
			//$(".menuDrawer-right").empty();
			//$(".menuDrawer-left li").removeClass("on");
		}

		$(".menuDrawer-left > ul > li > a").attr("aria-expanded", "false");
		$(this).attr("aria-expanded", "true");
	}
});

function openNav() {

	$(".menuDrawer-left").empty();
	$(".mainNav > li").eq(topIndex).find("> ul").clone(true, true).appendTo(".menuDrawer-left");

	var secondaryItemCount = 1;
	$(".menuDrawer-left > ul > li > a").each(function() {
		$(this).attr("id", "secondaryMenuItem-" + secondaryItemCount);
		secondaryItemCount++;
	});

	$(".menuDrawer-left li").each(function() {
		$("a", this).addClass("navLink-left");
		if ($("> li", this).length) {
			$(this).addClass("hasChildren");
		}
	});

	//$(".menuDrawer-left li").first().addClass("on");
	var fullLink = $(".menuDrawer-left li").eq(topIndex).find("> a").attr("href");
	var fullLinkText = $(".menuDrawer-left li").eq(topIndex).find("> a").text();

	$(".menuDrawer-mid").empty();
	//$(".mainNav > li").eq(topIndex).find("> ul li").first().find("> ul").clone(true, true).appendTo(".menuDrawer-mid");

	//$(".menuDrawer-mid > ul").prepend("<li><a class='button accent1' href='" + fullLink + "'>See All " + fullLinkText + "</a></li>");

	var featureTitle = $(".mainNav > li").eq(topIndex).find("> ul li").first().find("> a").data("title");
	var featureCaption = $(".mainNav > li").eq(topIndex).find("> ul li").first().find("> a").data("caption");
	var featureUrl = $(".mainNav > li").eq(topIndex).find("> ul li").first().find("> a").data("url");
	var featureThumb = $(".mainNav > li").eq(topIndex).find("> ul li").first().find("> a").data("thumb");
	var featureAlt = $(".mainNav > li").eq(topIndex).find("> ul li").first().find("> a").data("alt");

	//$(".menuDrawer-right").html("<div class='navFeatureBox'><div class='navFeatureBox-thumb'><img src='" + featureThumb + "' alt='" + featureAlt + "'></div><div class='navFeatureBox-content'><h2>" + featureTitle + "</h2><p>" + featureCaption + "</p><p><a class='arrowLink' href='" + featureUrl + "'>Read More</a></p></div></div>");

	$(".menuDrawer").slideDown(300, "easeOutQuint");
	var theId = $(".mainNav > li.on > button").attr("id");
	$(".menuDrawer").attr("aria-labelledby", theId);

	//$(".menuDrawer-left li").first().find("a").focus();

	$(".menuDrawer-left li").first().find("> a").click().attr("aria-expanded", "true");
}

function closeNav() {
	$(".menuDrawer").slideUp(300, "easeOutQuint");
}

$("body").on("keydown", ".mainNav > li > a, .mainNav > li > button", function(e) {
	if (overallWidth >= xl) {
		if (e.key === "Enter" || e.keyCode === 32) {
			e.preventDefault();
			if ($(this).hasClass("on")) {
				$(this).attr("aria-expanded", "false");
			} else {
				$(this).attr("aria-expanded", "true");
			}
			if (overallWidth < lg) {
				$(this).closest("ul").next("ul").find("> li").first().find("> a").focus();
			}
			$(this).click();
		} else if (e.key === "Tab" && e.shiftKey) {
			
		} else if (e.key === "Tab") {
			if ($(this).parent().hasClass("on")) {
				e.preventDefault();
				$(".menuDrawer-left li").first().find("> a").focus();
			}
		} else if (e.keyCode === 39) {
			e.preventDefault();
			if ($(this).parent().is(":last-child")) {
				$(this).closest("ul").find("li:first").find("a, button").focus();
			} else {
				$(this).parent().next().find("a, button").focus();
			}
		} else if (e.keyCode === 37) {
			if ($(this).parent().is(":first-child")) {
				$(this).closest("ul").find("li:last-child").find("a, button").focus();
			} else {
				$(this).parent().prev().find("a, button").focus();
			}
		} else if (e.keyCode === 40) {
			e.preventDefault();
			$(".menuDrawer-left li").first().find("> a").focus();
		}
	}
});

$("body").on("keydown", ".menuDrawer-left li a", function(e) {
	if (e.key === "Tab" && e.shiftKey) {
		if ($(this).parent().is(":first-child")) {
			e.preventDefault();
			$(".mainNav > li.on").find("a, button").focus();
		} else if ($(this).parent().prev().hasClass("on")) {
			e.preventDefault();
			$(".menuDrawer-right .navFeatureBox-content a").focus();
		}
	} else if (e.key === "Tab") {
		if ($(this).parent().hasClass("on")) {
			e.preventDefault();
			$(".menuDrawer-mid li").first().find("> a").focus();
		} else if ($(this).parent().is(":last-child")) {
			e.preventDefault();
			$(".mainNav > li.on").find("a, button").click().parent().next("li").find("> a, > button").focus();
		}

	} else if (e.keyCode === 32) {
		e.preventDefault();
		if ($(this).parent().hasClass("on")) {
			$(".menuDrawer-mid li").first().find("> a").focus();
		} else {
			$(this).attr("aria-expanded", "true");
			$(this).click();
		}
	} else if (e.key === "Enter") {
		e.preventDefault();
		if ($(this).hasClass("on")) {
			$(this).attr("aria-expanded", "false");
		} else {
			$(this).attr("aria-expanded", "true");
		}
		$(this).click();
	} else if (e.key === "Escape") {
		e.preventDefault();
		$(".mainNav li.on").find("a, button").focus().click();
	} else if (e.keyCode === 39) {
		e.preventDefault();
		if ($(this).parent().is(":last-child")) {
			$(this).closest("ul").find("li:first-child").find("a").focus();
		} else {
			$(this).parent().next().find("a").focus();
		}
	} else if (e.keyCode === 40) {
		e.preventDefault();
		if ($(this).parent().hasClass("on")) {
			$(".menuDrawer-mid li").first().find("> a").focus();
		}
	} else if (e.keyCode === 37) {
		e.preventDefault();
		if ($(this).parent().is(":first-child")) {
			$(this).closest("ul").find("li:last-child").find("a").focus();
		} else {
			$(this).parent().prev().find("a").focus();
		}
	} else if (e.keyCode === 38) {
		e.preventDefault();
		if ($(this).parent().is(":first-child")) {
			$(".mainNav li.on").find("a, button").focus();
		} else {
			$(this).parent().prev().find("a").focus();
		}
	}
});

$("body").on("keydown", ".menuDrawer-mid li a", function(e) {
	if (e.which === 9 && e.shiftKey) {
		if ($(this).parent().is(":first-child")) {
			e.preventDefault();
			$(".menuDrawer-left li.on a").focus();
		}
	}
	if (e.key === "Escape") {
		e.preventDefault();
		$(".menuDrawer-left li.on").find("a, button").focus().click();
	}
	if (e.keyCode === 39) {
		e.preventDefault();
		if ($(this).parent().is(":last-child")) {
			$(this).closest("ul").find("li:first-child").find("a").focus();
		} else {
			$(this).parent().next().find("a").focus();
		}
	}
	if (e.keyCode === 37) {
		e.preventDefault();
		if ($(this).parent().is(":first-child")) {
			$(this).closest("ul").find("li:last-child").find("a").focus();
		} else {
			$(this).parent().prev().find("a").focus();
		}
	}
	if (e.keyCode === 38) {
		if ($(this).parent().is(":first-child")) {
			$(".menuDrawer-left li.on a").focus();
		} else {
			$(this).parent().prev().find("a").focus();
		}
	}
});

$("body").on("keydown", ".menuDrawer a", function(e) {
	if (e.key === "Escape") {
		//$(".mainNav li.on").find("a").click().focus();
	}
});

$("body").on("keydown", ".navFeatureBox-content a", function(e) {
	if (e.which === 9 && e.shiftKey) {
		e.preventDefault();
		$(".menuDrawer-mid").find("li:last-child").find("a").focus();
	} else if (e.key === "Tab") {
		e.preventDefault();
		$(".menuDrawer-left li.on").next().find("> a").focus();
		if ($(".menuDrawer-left > li.on").is(":last-child")) {
			//$(".mainNav > li.on").next("li").find("a, button").focus();
		} else {
			//console.log("do this one");
			//$(".menuDrawer-left li.on").next("li").find("a").focus();
		}
	}
});