import $ from 'jquery';
import 'what-input';
import './nav.js';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

var overallWidth = $(window).outerWidth(),
		overallHeight = $(window).outerHeight(),
		md = 576,
		lg = 768,
		xl = 992,
		xxl = 1200;

$(window).resize(function() {
	overallWidth = $(window).outerWidth(),
	overallHeight = $(window).outerHeight();
});

// MOVE HEADER ELEMENTS FOR SCREEN SIZE
function moveHeaderElements() {
	if (overallWidth < xl) {
		if (!$(".mobileNavTop").length) {
			if (!$(".mobileMenu-drawer").length) {
				$("header").after("<div class='mobileMenu-drawer'></div>");
			}
			if ($(".menuDrawer").length) {
				$(".menuDrawer").remove();
			}
			$(".mainNavRow").show();
			$(".mainSearch-drawer").prependTo(".mobileMenu-drawer");
			if (!$(".mobileNavTop").length) {
				$(".topLogin").addClass("mobile").appendTo(".mobileMenu-drawer").wrap("<div class='mobileNavTop'></li>");
			}
			$(".mainNavRow").appendTo(".mobileMenu-drawer");
		}
	} else {
		if (!$(".menuDrawer").length) {
			$(".mainNavRow").appendTo("header");
			$(".mainSearch-drawer").appendTo(".mainNavRow");
			$(".topLogin").prependTo(".header-content");
			if (!$(".menuDrawer").length) {
				$("header").append("<div class='menuDrawer' role='region'><div class='grid-container'><div class='menuDrawer-inner'><div class='menuDrawer-left'></div><div class='menuDrawer-tertiary' role='region'><div class='menuDrawer-mid'></div><div class='menuDrawer-right'></div></div></div></div></div>");
			}
			if ($(".mobileMenu-drawer").length) {
				$(".mobileMenu-drawer").remove();
			}
		}
	}
}
moveHeaderElements();

$(window).resize(function() {
	moveHeaderElements();
});

// HIDE HEADER ON SCROLL DOWN
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $("header").outerHeight();

$(window).scroll(function(event) {
		didScroll = true;

		var scroll = $(window).scrollTop();
		if (scroll >= 400) {
			console.log("show it");
			if ($(".backToTop").length) {
				$(".backToTop").addClass("on");
			}
		} else {
			if ($(".backToTop").length) {
				$(".backToTop").removeClass("on");
			}
		}
});

setInterval(function() {
		if (didScroll) {
				hasScrolled();
				didScroll = false;
		}
}, 250);

function hasScrolled() {
		var st = $(window).scrollTop();

		// Make sure they scroll more than delta
		if (Math.abs(lastScrollTop - st) <= delta)
				return;

		// If they scrolled down and are past the navbar, add class .nav-up.
		// This is necessary so you never see what is "behind" the navbar.
		if (st > lastScrollTop && st > navbarHeight && overallWidth < xl) {
			// Scroll Down
			$("header").not(".sg-header").addClass("scrolled");
		} else {
			// Scroll Up
			if (st + $(window).height() < $(document).height()) {
				if (!$("body").hasClass("noScroll")) {
					$("header").removeClass("scrolled");
				}
			}
		}

		lastScrollTop = st;
}

$(window).resize(function() {
		navbarHeight = $("header").outerHeight();
		$(".headerShim").height(navbarHeight);
});

$(".headerShim").height(navbarHeight);

// HIDE/SHOW SEARCH TRAY
$(".mainSearch-toggle").click(function() {
	if ($(this).hasClass("on")) {
		$(this).removeClass("on");
		$(this).attr("aria-expanded", "false");
		$(".mainSearch-drawer").slideUp("fast");
		$(".mainSearch-drawer").attr("aria-hidden", "true");
	} else {
		$(this).addClass("on");
		$(this).attr("aria-expanded", "true");
		$(".mainSearch-drawer").slideDown("fast");
		$(".mainSearch-drawer").attr("aria-hidden", "false");
	}

	if (overallWidth >= xl) {
		$(".menuDrawer").slideUp(300);
		$("header .mainNav > li").removeClass("on");
	}
});

// SEARCH INPUT CLEAR
$(".mainSearch-input input").on('input',function(e) {
	if (!$(this).val() == "") {
		$(this).next("button").addClass("on");
	} else {
		$(this).next("button").removeClass("on");
	}
});

$(".mainSearch-input button.clear").click(function() {
	$(this).prev().val("");
	$(this).removeClass("on");
});

// MAIN SEARCH LABEL
$(".mainSearch-input input").focus(function() {
	$(".mainSearch-input").addClass("focus");
});

$(".mainSearch-input input").blur(function() {
	if ($(this).val().length <= 0) {
		$(".mainSearch-input").removeClass("focus");
	} else {

	}
});

// TAB WIDGET
if ($(".tabWidget").length) {
	var currentTab = 1;
	var currentContent = 1;
	$(".tabWidget-tabs").attr("role", "tablist");
	$(".tabWidget-tabs li").each(function() {
		$(this).attr("role", "presentation");
		$("a", this).attr("id", "tab" + currentTab).attr("aria-controls", "panel" + currentTab).attr("role", "tab").attr("aria-selected", "false");
		currentTab++;
	});
	$(".tabWidget-content").each(function() {
		$(this).attr("role", "tabpanel").attr("aria-hidden", "true").attr("aria-labelledby", "tab" + currentContent).attr("id", "panel" + currentContent);
		currentContent++;
	});
	$(".tabWidget-tabs li").first().addClass("on");
	$(".tabWidget-tabs li").first().find("a").attr("aria-selected", "true");
	$(".tabWidget-content").first().addClass("on").attr("aria-hidden", "false");
}

$(".tabWidget-tabs a").click(function(e) {
	e.preventDefault();
	$(".tabWidget-tabs li").removeClass("on");
	$(".tabWidget-tabs li a").attr("aria-selected", "false");
	$(".tabWidget-content").removeClass("on").attr("aria-hidden", "true");

	var thisIndex = $(this).parent().index();
	$(this).parent().addClass("on");
	$(this).attr("aria-selected", "true");
	$(".tabWidget-content").eq(thisIndex).addClass("on").attr("aria-hidden", "false");
});

// MAKE MEMBER CITY INITIAL DROP DOWN FROM BUTTONS
if ($(".directorySearch").length) {
	makeDirectorySearchDrop();
}

function makeDirectorySearchDrop() {
	$("<div class='dropdownWrapper initials'><select id='initials' name='initials'></select></div>").insertAfter(".directoryInitialBox-wrapper");

	$(".directoryInitialBox-wrapper input").each(function() {
		var value = $(this).val();
		$("<option value='" + value + "'>" + value + "</option>").appendTo(".dropdownWrapper.initials select");
	});
}

// ADD BLOG CLASS TO MAIN ON BLOG
if ($(".blogMeta-photo").length) {
	$(".blogMeta-photo").each(function() {
		$(this).closest(".blogMeta").addClass("metaHasPhoto");
	});
}

// ACCORDION
if ($(".accordion").length) {
	accordionSetup();
}

function accordionSetup() {
	var current = 1;
	$(".accordion-panel").each(function() {
		$(this).find(".accordion-header button").attr("id", "accordion-header-" + current).attr("aria-controls", "accordion-content-" + current);

		$(this).find(".accordion-content").attr("id", "accordion-content-" + current).attr("aria-labelledby", "accordion-header-" + current);

		current++;
	});
}

$(".accordion-toggle").click(function(e) {
	e.preventDefault();
	if ($(this).hasClass("on")) {
		// close all panels
		$(".accordion-content").slideUp("fast");
		$(".accordion-content").attr("aria-hidden", "true");

		// remove on class from all toggles
		$(".accordion-toggle").removeClass("on");
		$(".accordion-panel").removeClass("on");
		$(".accordion-toggle").attr("aria-expanded", "false");
		$(".accordion-toggle").attr("aria-selected", "false");
	} else {
		// close all panels
		$(".accordion-content").slideUp("fast");
		$(".accordion-content").attr("aria-hidden", "true");

		// remove on class from all toggles
		$(".accordion-toggle").removeClass("on");
		$(".accordion-panel").removeClass("on");
		$(".accordion-toggle").attr("aria-expanded", "false");
		$(".accordion-toggle").attr("aria-selected", "false");

		// add class and attr to this toggle
		$(this).addClass("on");
		$(this).closest(".accordion-panel").addClass("on");
		$(this).attr("aria-expanded", "true");
		$(this).attr("aria-selected", "true");

		// open this panel
		$(this).closest(".accordion-panel").find(".accordion-content").slideDown("fast");
		$(this).closest(".accordion-panel").find(".accordion-content").attr("aria-hidden", "false").addClass("on");
	}
});

// SHOW/HIDE ADD-TO-CALENDAR DROP DOWN
$(".eventInfoBox a.calendar").click(function(e) {
	e.preventDefault();
	$(this).parent().find("> ul").slideToggle("fast");
});

$(".atcb-list, .eventInfoBox a.calendar").keyup(function(e) {
	if(e.key === "Escape") {
		$(".atcb-list").slideUp("fast");
	}
});

// SWAP SUB BANNER AND BREADCRUMBS
if ($(".subBanner").length) {
	$(".subBanner").insertBefore(".breadcrumbs");
}

// MOVE LIST ITEM THUMBNAILS
function moveThumbnails() {
	$(".listItem").each(function() {
		if ($(".blogMeta-photoContainer", this).length) {
			if (overallWidth >= xxl) {
				$(".blogMeta-photoContainer", this).prependTo($(this));
			} else {
				$(".blogMeta-photoContainer", this).prependTo($(".blogMeta", this));
			}
		}
	});
}

$(window).resize(function() {
	moveThumbnails();
});

// BACK TO TOP LINK
$(".backToTop").click(function(e) {
	e.preventDefault();
	$('html, body').animate({
		scrollTop: $("#top").offset().top
	}, 400);
});

// FIX GENERAL BODY HEIGHT ON PAGES WITH LITTLE OR NO CONTENT
var headerHeight = $("header").outerHeight();
var footerHeight = $("footer").outerHeight();
var generalBodyHeight = $(".generalBody").outerHeight();
if ((headerHeight + footerHeight + generalBodyHeight) < overallHeight) {
	$(".generalBody").css({
		"min-height": overallHeight - (headerHeight + footerHeight) + "px"
	});
}

// SCROLL TO ERRORS ON FORM
if ($(".eat-error-sum").length) {
	$('html, body').animate({
			scrollTop: $(".eat-error-sum").offset().top
	}, 500);
	$(".eat-error-list li:first a").focus();
}

if ($(".form-confirmation").length) {
	$('html, body').animate({
			scrollTop: $(".form-confirmation").offset().top
	}, 500);
}

// CHECK FOR EMPTY UL'S IN ASIDE
$(".asideBox ul").each(function() {
	if (!$("> li", this).length) {
		$(this).addClass("isEmpty");
	}
});